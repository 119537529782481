<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-select style="margin-right: 5px;"
                 v-model="dataForm.serveType"
                 clearable
                 placeholder="请选择服务类型……">
        <el-option
            v-for="item in serveTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-select  style="margin-right: 5px;"
                  v-model="dataForm.objType"
                 clearable
                 placeholder="请选择服务对象类型……">
        <el-option
            v-for="item in objTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <!--        <el-button v-if="isAuth('wechat:servecontent:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>-->
        <!--        <el-button v-if="isAuth('wechat:servecontent:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>-->
      </el-form-item>
    </el-form>
    <export-excel :all-data="dataList"
                  :export-excel-array="columns"
                  :select-list-array="dataListSelections"
                  :excel-name="'服务填报信息'"
    />
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
      <el-table-column
          type="selection"
          header-align="center"
          fixed
          align="center"
          width="50">
      </el-table-column>
      <el-table-column
          prop="uname"
          header-align="center"
          width="120"
          fixed
          align="center"
          label="填报人姓名">
      </el-table-column>
      <el-table-column
          prop="wechatName"
          header-align="center"
          width="120"
          align="center"
          label="填报人微信名">
      </el-table-column>
      <el-table-column
          prop="servers"
          header-align="center"
          min-width="180"
          align="center"
          label="服务人">
        <template slot-scope="scope">
          <div v-if="scope.row.servers" type="text"
               size="small">
            <el-tag style="margin: 2px;" size="mini" v-for="(item,index) in scope.row.servers.split(/[;；]/g)"
                    :key="index+'-'+item">{{ item }}
            </el-tag>
          </div>
          <span v-else>无填写</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="mainOrgName"
          header-align="center"
          width="150"
          align="center"
          label="单位">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              title="单位"
              width="300"
              trigger="hover"
              :content="scope.row.mainOrgName">
            <span slot="reference">{{ $utils.getLimitText(scope.row.mainOrgName) }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="secondOrgName"
          header-align="center"
          align="center"
          width="120"
          label="种质(圃)">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              title="种质(圃)"
              width="300"
              trigger="hover"
              :content="scope.row.secondOrgName">
            <span slot="reference">{{ $utils.getLimitText(scope.row.secondOrgName) }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="sid"
          header-align="center"
          align="center"
          label="服务类型">
        <template slot-scope="scope">
          <span>{{ scope.row.sname ? scope.row.sname : $utils.getNameById(scope.row.sid, serveTypeList) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="serveObjectName"
          header-align="center"
          width="120"
          align="center"
          label="服务对象名称">
      </el-table-column>
      <el-table-column
          prop="oid"
          header-align="center"
          width="120"
          align="center"
          label="服务对象类型">
        <template slot-scope="scope">
          <span>{{ scope.row.oname ? scope.row.oname : $utils.getNameById(scope.row.sid, objTypeList) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="contactName"
          header-align="center"
          width="120"
          align="center"
          label="联系人">
      </el-table-column>
      <el-table-column
          prop="contactPhone"
          header-align="center"
          width="150"
          align="center"
          label="联系人手机">
      </el-table-column>
      <el-table-column
          prop="serveContent"
          header-align="center"
          min-width="150"
          label="服务内容">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              title="服务内容"
              width="200"
              trigger="hover"
              :content="scope.row.serveContent">
            <span slot="reference">{{ $utils.getLimitText(scope.row.serveContent) }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="serveNum"
          header-align="center"
          align="center"
          label="服务人次">
      </el-table-column>
      <el-table-column
          prop="imgs"
          header-align="center"
          align="center"
          label="服务图片">
        <template slot-scope="scope">
          <el-button v-if="scope.row.imgs" type="text" size="small" @click="showImage(scope.$index,scope.row.id)">查看
          </el-button>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          header-align="center"
          align="center"
          width="120"
          label="服务地址">
      </el-table-column>
      <el-table-column
          prop="serveDate"
          header-align="center"
          align="center"
          min-width="120"
          label="服务日期">
      </el-table-column>
      <el-table-column
          prop="miniProgVersion"
          header-align="center"
          min-width="110"
          align="center"
          label="小程序版本">
      </el-table-column>
      <el-table-column
          prop="updateDate"
          header-align="center"
          align="center"
          min-width="140"
          label="修改时间">
        <template slot-scope="scope">
          <span>{{ $utils.dateFormat('yyyy-MM-dd hh:mm', scope.row.updateDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="createDete"
          header-align="center"
          align="center"
          min-width="140"
          label="创建时间">
        <template slot-scope="scope">
          <span>{{ $utils.dateFormat('yyyy-MM-dd hh:mm', scope.row.createDete) }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        fixed="right"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        width="150"-->
      <!--        label="操作">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>-->
      <!--          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <el-dialog
        title="服务图片展示"
        :visible.sync="showImg"
        width="50%">
      <template v-if="currentObj && currentObj.imgs">
        <el-image
            style="width: 50%;padding: 2px;"
            v-for="url in currentObj.imgs.split(';')"
            :key="url"
            :src="filePath+url" lazy>
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </template>
      <span v-else>没有服务图片</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showImg = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddOrUpdate from './servecontent-add-or-update'
import config from "../../../config";
import {mapGetters} from 'vuex'
import PageMixin from "@/mixins/Page";
import ExportExcel from '@/components/export'

export default {
  data() {
    return {
      filePath: config.filePath,
      dataForm: {
        serveType: null,
        objType: null
      },
      showImg: false,
      currentObj: null,
      columns: [
        {
          prop: 'id',
          label: 'ID',
          formatterFlag: false
        },
        {
          prop: 'uname',
          label: '填报人姓名',
          formatterFlag: false,
        },
        {
          prop: 'wechatName',
          label: '微信名',
          formatterFlag: false,
          // formatterFlag: true,
          // formatterType: 'common-type',
          // formatterInfo: [{value: 0, label: '未完成'}, {value: 1, label: '已完成'}]
        },
        {
          prop: 'servers',
          label: '服务人',
          formatterFlag: false,
        },
        {
          prop: 'mainOrgName',
          label: '单位名称',
          formatterFlag: false,
        },
        {
          prop: 'secondOrgName',
          label: '种质(圃)名称',
          formatterFlag: false,
        },
        {
          prop: 'miniProgVersion',
          label: '小程序版本号',
          formatterFlag: false,
        },
        {
          prop: 'sname',
          label: '服务类型',
          formatterFlag: false,
        },
        {
          prop: 'oname',
          label: '服务对象类型',
          formatterFlag: false,
        },
        {
          prop: 'serveObjectName',
          label: '服务对象名称',
          formatterFlag: false,
        },
        {
          prop: 'contactName',
          label: '联系人姓名',
          formatterFlag: false,
        },
        {
          prop: 'contactPhone',
          label: '联系人手机',
          formatterFlag: false,
        },
        {
          prop: 'serveContent',
          label: '服务内容',
          formatterFlag: false,
        },
        {
          prop: 'serveDate',
          label: '服务时间',
          formatterFlag: false,
        },
        {
          prop: 'serveNum',
          label: '服务人次',
          formatterFlag: false,
        },
        {
          prop: 'address',
          label: '服务地址',
          formatterFlag: false,
        },
        {
          prop: 'uid',
          label: '填报人标识符',
          formatterFlag: false,
        },
        {
          prop: 'updateDate',
          label: '修改时间',
          formatterFlag: true,
          formatterType: 'time-type',
        },
        {
          prop: 'createDete',
          label: '创建时间',
          formatterFlag: true,
          formatterType: 'time-type',
        },
      ]
    }
  },
  mixins: [PageMixin],
  computed: {
    ...mapGetters({
      serveTypeList: 'serveTypeList',
      objTypeList: 'objTypeList'
    })
  },
  components: {
    AddOrUpdate, ExportExcel
  },
  created() {
    this.getServeAndObjTypeList();
  },
  activated() {
    this.getDataList()
  },
  methods: {
    getServeAndObjTypeList() {
      this.$store.dispatch('wechat/getObjTypeList')
      this.$store.dispatch('wechat/getServeTypeList')
    },
    // 获取数据列表
    getDataList() {
      const params = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'order': 'DESC',
        'sidx': 'create_dete'
      }
      if (this.dataForm.serveType) {
        params['sid'] = this.dataForm.serveType
      }
      if (this.dataForm.objType) {
        params['oid'] = this.dataForm.objType
      }
      this.doGetList('/wechat/servecontent/list', params)
    },
    showImage(index/*, id*/) {
      this.currentObj = this.dataList[index];
      this.showImg = true;
    },
    // 删除
    deleteHandle(id) {
      this.doDelete('/wechat/servecontent/delete', id)
    }
  }
}
</script>
