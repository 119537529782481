<template>
  <div style="margin-top: -65px;float: right;">
    <el-button-group class="add-button">
      <el-button @click="exportExcelSelect">按勾选导出</el-button>
      <el-button @click="exportExcel">按查询结果导出</el-button>
    </el-button-group>
    <export-excel ref="myChild"
                  :export-excel-info="exportExcelInfo"
                  :table-data="exportData"
                  :export-excel-array="exportExcelArray"></export-excel>
  </div>
</template>
<script>
import ExportExcel from './export-excel'

export default {
  components: {
    ExportExcel
  },
  props: {
    //导出表格字段及formatter信息
    exportExcelArray: {
      type: Array,
      default: () => []
    },
    allData: {
      type: Array,
      default: () => []
    },
    selectListArray: {
      type: Array,
      default: () => []
    },
    excelName: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      //导出excel表格id及excel名称
      exportExcelInfo: {
        excelId: 'record-table',
        excelName: '信息导出'
      },
      exportData: [],
    }
  },
  mounted() {
    if (this.excelName) {
      this.exportExcelInfo.excelName = this.excelName;
    }
  },
  methods: {
    exportExcelSelect() {
      if (this.selectListArray.length === 0) {
        this.$message.warning('请勾选操作项')
        return;
      }
      //将选中项传给this. tableAllData
      this.exportData = this.selectListArray
      this.exportExcel();
    },
    exportAll() {
      if (this.allData.length === 0) {
        this.$message.warning('查询结果没有数据，请您重新查找！')
        return;
      }
      //将选中项传给this. tableAllData
      this.exportData = this.allData
      this.exportExcel();
    },
    exportExcel() {
      //需要延时调导出方法，为了等待数据初始化到列表中
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.myChild.exportExcel();
        }, 500)
      })


    }
  },
  watch: {
    allData: {
      handler() {
        this.exportData = JSON.parse(JSON.stringify(this.allData))
      }
    }
  }
}
</script>